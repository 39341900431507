import Swal from 'sweetalert2';

import { types } from "../types/types";
import { fetchConToken, archivoConToken } from '../helpers/fetch';
import { openModal, openModalBottom } from './ui';

export const startNewUsuario = ( values, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            console.log( 'new Usuario', values );
            const resp = await fetchConToken( 'usuarios', values, 'POST' );
            const body = await resp.json();
            if( body.ok ) {
                const datos = {
                    text: [ `Usuario ${ values.nombre } creado correctamente` ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
                dispatch( addNewUsuario( body.usuario._id, body.usuario ) );
            }else{
                console.log('Error!!!');
                console.log(body.errors);
            }
        } catch (error) {
            console.log('Error!!!');
            console.log( error );
            const datos = {
                title: 'Error',
                text: [ 'Ha habido un error' ],
                buttonText: 'Cerrar'
            }
            dispatch( openModal( datos ) );
        }
    }
};

export const activeUsuario = ( _id, usuario ) => ({
    type: types.usuariosActive,
    payload: {
        _id,
        ...usuario
    }
});

export const unactiveUsuario = () => ({
    type: types.usuariosUnactive
});

export const addNewUsuario = ( _id, usuario ) => ({
    type: types.usuariosAddNew,
    payload: {
        _id,
        ...usuario
    }
})

export const startLoadingUsuario = ( uid, empresa ) => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `usuarios/usuario`, {usuario: uid, empresa}, 'POST' );
            const body = await resp.json();
            if( body.usuario ){
                //console.log( 'body', body );
                dispatch( setUsuarios( [body.usuario] ));
            } else {
                //console.log( 'nobody', body );
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingUsuarios = () => {
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'usuarios' );
            const body = await resp.json();
            dispatch( setUsuarios( body.usuarios ));
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingUsuariosEmpresa = ( empresa ) => {
    //console.log( 'startLoadingUsuariosEmpresa' );
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( 'usuarios/empresa', {empresa}, 'POST' );
            const body = await resp.json();
            if( body.usuarios ){
                dispatch( setUsuarios( body.usuarios ));
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const startLoadingClientesEmpresa = ( empresa, from = 0, limit = 10, sort = 'creacion', asc = true ) => {
    //console.log( 'startLoadingClientesEmpresa' );
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `usuarios/clientes/${empresa}?limit=${limit}&from=${from}`, {sort, asc} ); 
            const body = await resp.json();
            // console.log( body );
            if( body.clientes ){
                dispatch( setUsuarios( body.clientes, body.total ));
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const startSearchClientesEmpresa = ( empresa, data, from = 0, limit = 10, sort = 'creacion', asc = true ) => {
    console.log( 'data', data );
    return async( dispatch ) => {
        try {
            const resp = await fetchConToken( `usuarios/buscar/${data}`, {empresa, from, limit, sort, asc}, 'POST' );
            const body = await resp.json();
            if( body.clientes ){
                dispatch( setUsuarios( body.clientes, body.total ));
            }
        } catch (error) {
            console.log(error);
        }
    }
};

export const setUsuarios = ( usuarios, total ) => ({
    type: types.usuariosLoad,
    payload: {usuarios, total}
})

export const startSaveUsuario = ( usuario, swal = true ) => {
    return async( dispatch, getState ) => {
        
        try {
            const resp = await fetchConToken( `usuarios/${ usuario._id }`, usuario, 'PUT' );
            const body = await resp.json();
            if ( body.ok ) {
                dispatch( refreshUsuario( usuario._id, body.usuario ) );
                const datos = {
                    text: [ 'Tus datos se han actualizado correctamente.' ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            } else {
                const datos = {
                    text: [ body.msg ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
};

export const usuarioChangePassword = ( empresa, newPassword, swal = true ) => {
    return async( dispatch, getState ) => {
        try {
            const resp = await fetchConToken( `usuarios/change-password`, { empresa, password: newPassword }, 'POST' );
            const body = await resp.json();
            if ( body.ok ) {
                const datos = {
                    text: [ 'Tu password se ha actualizado correctamente.' ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            } else {
                console.log( body );
                const datos = {
                    text: [ body.msg ],
                    timeOut: 3
                }
                dispatch( openModalBottom( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
};

export const refreshUsuario = ( id, usuario ) => ({
    type: types.usuariosUpdated,
    payload: {
        ...usuario
    }
});

/* export const startUploading = ( archivo, idUsuario ) => {

    return async ( dispatch, getState ) => {
        try {
            Swal.fire({
                title: 'Subiendo...',
                text: 'Por favor, espere...',
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading();
                }
            });

            const formData = new FormData();
            formData.append('archivo', archivo);
            formData.append('file_name', 'archivo');
    
            const resp = await archivoConToken( `usuarios/upload/${ idUsuario }`, formData, 'POST' );
            const body = await resp.json();

            console.log( body );

            Swal.close();
        } catch (error) {
            throw new Error();
        }  

        Swal.close();
    }
}; */

export const startUploading = ( archivo, usuario, nombre, previa ) => {

    return async ( dispatch, getState ) => {
        try {           
            const formData = new FormData();
            // console.log( archivo );
            formData.append('archivo', archivo);
            formData.append('empresa', usuario.empresa);
            formData.append('nombre', nombre);
            formData.append('previa', previa);
            formData.append('file_name', 'archivo');
    
            await archivoConToken( `usuarios/upload/${ usuario._id }`, formData, 'POST' );

            //console.log( body );

            //Swal.close();
        } catch (error) {
            throw new Error();
        }  

        Swal.close();
    }
};

export const usuarioStartDelete = ( id, empresa ) => {
    return async( dispatch, getState ) => {

        try {
            const resp = await fetchConToken( `usuarios/${ id }`, { empresa }, 'DELETE' );
            const body = await resp.json();

            if ( body.ok ) {
                // console.log( body, id );
                dispatch( usuarioDeleted( id ) );
            } else {
                const datos = {
                    title: 'Error',
                    text: [ body.msg ],
                    buttonText: 'Cerrar'
                }
                dispatch( openModal( datos ) );
            }

            
        } catch (error) {
            console.log(error);
        }
    }
}

const usuarioDeleted = ( id ) => ({
    type: types.usuariosDelete,
    payload: id
});

export const usuarioLogout = () => ({
    type: types.usuariosLogoutCleaning
});
