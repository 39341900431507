import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { authReducer } from '../reducers/authReducer';
import { usuariosReducer } from '../reducers/usuariosReducer';
import { uiReducer } from '../reducers/uiReducer';
import { empresasReducer } from '../reducers/empresasReducer';
import { sucursalesReducer } from '../reducers/sucursalesReducer';
import { serviciosReducer } from '../reducers/serviciosReducer';
import { horariosReducer } from '../reducers/horariosReducer';
import { citasReducer } from '../reducers/citasReducer';
import { profesionalesReducer } from '../reducers/profesionalesReducer';
import { promocionesReducer } from '../reducers/promocionesReducer';
import { productosReducer } from '../reducers/productosReducer';
import { pedidosReducer } from '../reducers/pedidosReducer';
import { variantesReducer } from '../reducers/variantesReducer';
import { categoriasReducer } from '../reducers/categoriasReducer';
import { comentariosReducer } from '../reducers/comentariosReducer';
import { vacacionesReducer } from '../reducers/vacacionesReducer';
import { sectoresReducer } from '../reducers/sectoresReducer';
import { planesReducer } from '../reducers/planesReducer';
import { listadoClientesEmpresaReducer } from '../reducers/listadoClientesEmpresaReducer';
import { textLegalReducer } from '../reducers/textLegalReducer';

const composeEnhancers = (process.env.NODE_ENV === "development" && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    auth: authReducer,
    usuarios: usuariosReducer,
    ui: uiReducer,
    empresas: empresasReducer,
    sucursales: sucursalesReducer,
    vacaciones: vacacionesReducer,
    servicios: serviciosReducer,
    horarios: horariosReducer,
    citas: citasReducer,
    comentarios: comentariosReducer,
    profesionales: profesionalesReducer,
    promociones: promocionesReducer,
    productos: productosReducer,
    pedidos: pedidosReducer,
    variantes: variantesReducer,
    categorias: categoriasReducer,
    sectores: sectoresReducer,
    planes: planesReducer,
    listadoClientesEmpresa: listadoClientesEmpresaReducer,
    textLegal: textLegalReducer
});

export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware( thunk )
    )
);