import React, { useEffect, useState , useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openModalBottom, setMenuLateral } from '../../actions/ui';

import { startLoadingClientesEmpresa, startSearchClientesEmpresa, usuarioLogout, usuarioStartDelete } from '../../actions/usuarios';
import { AddIcon, ArrowDownSmallIcon, BuscarIcon, CloseIcon } from '../../assets/svg/iconos';
import { ClienteRowItem } from './components/ClienteRowItem';
import { ModalEliminarCliente } from './components/modales/ModalEliminarCliente';
import { DownloadCSV } from '../../components/common/DownloadCSV';
import { startLoadingListadoClientesEmpresa } from '../../actions/citas';


export const Clientes = ({history}) => {

    const dispatch = useDispatch();
    const { empresa } = useSelector(state => state.auth);
    const { colores, miniLateral } = useSelector(state => state.ui);
    const { usuarios, total: totalUsuarios } = useSelector(state => state.usuarios);

    const [initialSearch, setInitialSearch] = useState(false);
    const [searchActive, setSearchActive] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [userList, setUserList] = useState([]);
    const [ , setCurrentScreen] = useState('main');
    const [from, setFrom] = useState(0);

    const [isLoading, setIsLoading] = useState(false);

    const [modalEliminarClienteOpened, setModalEliminarClienteOpened] = useState(false);
    const [dataModalEliminar, setDataModalEliminar] = useState({
        title: '¿Eliminar profesional?',
        text: [],
        buttonText: 'Eliminar',
        button2Text: 'Cancelar'
    });

    const [selectedId, setSelectedId] = useState(''); 

    const [myTimeOut, setMyTimeOut] = useState(null);

    const [orderBy, setOrderBy] = useState('creacion');
    const [asc, setAsc] = useState(true);
    const [ascNombre, setAscNombre] = useState(true);
    const [ascTelefono, setAscTelefono] = useState(true);
    const [ascEmail, setAscEmail] = useState(true);
    const [ascNacimiento, setAscNacimiento] = useState(true);
    const [ascCreacion, setAscCreacion] = useState(true);
    const [ascUltimaReserva, setAscUltimaReserva] = useState(true);

    const listadoClientesEmpresa = useSelector(state => state.listadoClientesEmpresa.listadoClientesEmpresa);

    const headersCSV = 'Id|Nombre|Apellido|Telefono|Email|Primera Cita|Ultima Cita|Frecuencia media|Numero veces';

    const LIMIT = 10;

    const inputRef = useRef();

    useEffect(() => {
		dispatch(startLoadingListadoClientesEmpresa(empresa));		  
    }, []);

    useEffect(() => {
      dispatch( startLoadingClientesEmpresa( empresa, from | 0, LIMIT, orderBy ) );
      setInitialSearch( true );
      dispatch( setMenuLateral('clientes') );


      return () => {
        dispatch( usuarioLogout() );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // console.log('Cambia citas');
        if( totalUsuarios === 0 ){
            setIsLoading( true );
            setMyTimeOut(
                setTimeout(() => {
                    if( isLoading ){
                        dispatch( openModalBottom({text: ['No se han encontrado clientes o ha expirado el tiempo de espera'], timeOut: 4}) );
                    }
                    setIsLoading( false );
                }, 3000)
            )
        } else {
            setIsLoading( false );
            clearTimeout( myTimeOut );
            setTimeout( null );
        }

        return () => {
            clearTimeout( myTimeOut );
            setTimeout( null );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usuarios, userList]);

    useEffect(() => {
        procesaClientes( usuarios, false );
    }, [usuarios]);
    

    useEffect( () => {
        if( searchTxt.trim().length < 3 ){
            if( !initialSearch ){
                setFrom( 0 );
                dispatch( startLoadingClientesEmpresa( empresa, 0, LIMIT, orderBy, asc ) );
            }
        } else {
            dispatch( startSearchClientesEmpresa( empresa, searchTxt.trim(), from | 1, LIMIT, orderBy, asc ) );
            setInitialSearch( false );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTxt]);    

    const paginacion = ( valor ) => {
        if( searchTxt.trim().length < 3 ){
            if( valor === -1000 ) {
                setFrom( 0 );
                dispatch( startLoadingClientesEmpresa( empresa, 0, LIMIT, orderBy, asc ) );
            } else if( valor === 1 ){
                setFrom( from + LIMIT );
                dispatch( startLoadingClientesEmpresa( empresa, from + LIMIT, LIMIT, orderBy, asc ) );
            } else if( valor === 1000000000 ){
                setFrom( (Math.ceil( totalUsuarios / LIMIT) * LIMIT) - LIMIT );
                dispatch( startLoadingClientesEmpresa( empresa, (Math.ceil( totalUsuarios / LIMIT) * LIMIT) - LIMIT, LIMIT, orderBy, asc ) );
            } else {
                if( from < LIMIT ) return;
                setFrom( from - LIMIT );
                dispatch( startLoadingClientesEmpresa( empresa, from - LIMIT, LIMIT, orderBy, asc ) );
            }
        } else {
            if( valor === -1000 ) {
                setFrom( 0 );
                dispatch( startSearchClientesEmpresa( empresa, searchTxt.trim(), 0, LIMIT, orderBy, asc ) );
            } else if( valor === 1 ){
                setFrom( from + LIMIT );
                dispatch( startSearchClientesEmpresa( empresa, searchTxt.trim(), from + LIMIT, LIMIT, orderBy, asc ) );
            } else if( valor === 1000000000 ){
                setFrom( (Math.ceil( totalUsuarios / LIMIT) * LIMIT) - LIMIT );
                dispatch( startSearchClientesEmpresa( empresa, searchTxt.trim(), Math.ceil( totalUsuarios / LIMIT), LIMIT, orderBy, asc ) );
            } else {
                if( from < LIMIT ) return;
                setFrom( from - LIMIT );
                dispatch( startSearchClientesEmpresa( empresa, searchTxt.trim(), from - LIMIT, LIMIT, orderBy, asc ) );
            }
            
        }
        
    }

    const procesaClientes = ( clientes, filtro = true ) => {
        let newClientes = [];
        // console.log( 'procesaClientes' );
        let contador = 0;
        clientes.forEach( cliente => {
            if( !cliente.deleted ){
                newClientes = [ ...newClientes, cliente ];
                contador++;
            }
            if( filtro ){
                if( contador > 14 ){
                    return;
                }
            }
        });
        // console.log( 'newClientes', newClientes );
        setUserList( newClientes );
    }

    const changeOrderItem = ( item ) => {
        let newAsc = true;
        const mismaColumna = orderBy === item;
        if( mismaColumna ){
        switch (item) {
            case 'nombre':
                newAsc = !ascNombre;
                setAscNombre( !ascNombre );
                break;
            case 'telefono':
                newAsc = !ascTelefono;
                setAscTelefono( !ascTelefono );
                break;
            case 'email':
                newAsc = !ascEmail;
                setAscEmail( !ascEmail );
                break;
            case 'nacimiento':
                newAsc = !ascNacimiento;
                setAscNacimiento( !ascNacimiento );
                break;
            case 'creacion':
                newAsc = !ascCreacion;
                setAscCreacion( !ascCreacion );
                break;
            case 'ultimaReserva':
                newAsc = !ascUltimaReserva;
                setAscUltimaReserva( !ascUltimaReserva );
                break;
            default:
                break;
        }
        }
        if( !mismaColumna ){
            setOrderBy( item );
        }
        setAsc( newAsc );
        resetAscs( item );
        
        dispatch( startLoadingClientesEmpresa( empresa, from | 0, LIMIT, item, newAsc ) );
        setInitialSearch( true );
    }

    const resetAscs = ( value = 'creacion' ) => {
        if (value !== 'nombre'){ setAscNombre( true ) };
        if (value !== 'telefono'){ setAscTelefono( true ) };
        if (value !== 'email'){ setAscEmail( true ) };
        if (value !== 'nacimiento'){ setAscNacimiento( true ) };
        if (value !== 'creacion'){ setAscCreacion( true ) };
        if (value !== 'ultimaReserva'){ setAscUltimaReserva( true ) };
    }

    const clickSearch = () => {
        if( searchActive ){
            setSearchTxt('');
        }
        setSearchActive( !searchActive );
        setTimeout(() => {
            inputRef.current.focus();
        }, 250);
    };

    const clickDeleteSearch = () => {
        setSearchTxt('');
    }

    const handleSearchChange = (e) => {
        setSearchTxt( e.target.value );
    }

    const clickAdd = () => {
        setCurrentScreen('new');
        history.push({
            pathname: `/cliente/new`,
            state: {
                activeTab: 'ultima'
            }
        });
    };
    
    const editCliente = ( usuario ) => {
        history.push({
            pathname: `/cliente/${usuario._id}`,
            state: {
                usuario
            }
        });
    };

    const deleteClienteModal = ( profId, name ) => {
        setSelectedId( profId );
        setDataModalEliminar({
            ...dataModalEliminar,
            text: [`¿Estás seguro de que deseas eliminar a ${name}?`]
        })
        setModalEliminarClienteOpened( true );
    };

    const deleteClienteResponse = ( value ) => {
        if( value === 1 && selectedId !== '' ){
            // console.log( 'Borrar id:', selectedId );
            dispatch( usuarioStartDelete( selectedId, empresa ) );
        }
    }

    return (
        <>
        <div 
            className='clientes'
            style={{
                '--colorPrincipal': colores.colorPrincipal,
                '--colorSecundario': colores.colorSecundario,
                '--color3': colores.color3,
                '--color4': colores.color4,
                '--color5': colores.color5,
                '--color6': colores.color6,
                '--color7': colores.color7,
            }}
        >
            <div className={`preheader ${!miniLateral && 'compress'}`}>
                <div className="title">
                    <h1 style={{color: colores.colorPrincipal}}>Clientes</h1>
                    
                </div>
                <div className='selector'>
                    {listadoClientesEmpresa?.length>0 && <DownloadCSV headers={headersCSV} data={listadoClientesEmpresa} fileName={'listadoClientesEmpresa'} buttonText={'Exportar'} delimiter={';'}/>}
                </div>
                <div className="botonera">
                    <div 
                        className={`btn-search ${searchActive && 'active'}`}
                        style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                    >
                        <div className='search-input'>
                            <input 
                                type={'text'}
                                placeholder={'Buscar por nombre'}
                                value={ searchTxt }
                                onChange={ handleSearchChange }
                                ref={inputRef}
                            />
                            {
                                searchTxt.trim().length > 2 &&
                                <span className='cantidad'>
                                    ({ totalUsuarios })
                                </span>
                            }
                        </div>
                        {
                            searchTxt.trim().length < 1 &&
                            <div
                                className='icono'
                                onClick={clickSearch}
                            > 
                                <BuscarIcon
                                    color={ colores.colorPrincipal }
                                    className="icon-inverse"
                                />
                            </div>
                        }
                        {
                            searchTxt.trim().length >= 1 &&
                            <div
                                className='icono'
                                onClick={ clickDeleteSearch }
                            >
                                <CloseIcon
                                    color={ colores.colorPrincipal }
                                    className="icon-inverse"
                                />
                            </div>
                        }
                        
                    </div>
                    
                    <div 
                        className="btn-add"
                        style={{ backgroundColor: colores.color6, color: colores.colorPrincipal }}
                        onClick={ clickAdd }
                    >
                        <div className='icono'>
                            <AddIcon
                                color={ colores.colorPrincipal }
                            />
                        </div>
                        
                    </div>
                </div>
            </div>
            <div className="table-clientes">
                <div className='table-row headers-row'>
                    <div 
                        className='column name'
                        onClick={ () => changeOrderItem('nombre') }
                    >
                        <p>Cliente</p>
                        {
                            (orderBy === 'nombre') &&
                            <span
                                className={`${!ascNombre && 'desc'}`}
                            ><ArrowDownSmallIcon /></span>
                        }
                    </div>
                    <div 
                        className='column phone'
                        onClick={ () => changeOrderItem('telefono') }
                    >
                        <p>Teléfono</p>
                        {
                            (orderBy === 'telefono') &&
                            <span
                                className={`${!ascTelefono && 'desc'}`}
                            ><ArrowDownSmallIcon /></span>
                        }
                    </div>
                    <div 
                        className='column email'
                        onClick={ () => changeOrderItem('email') }
                    >
                        <p>E-mail</p>
                        {
                            (orderBy === 'email') &&
                            <span
                                className={`${!ascEmail && 'desc'}`}
                            ><ArrowDownSmallIcon /></span>
                        }
                    </div>
                    <div 
                        className='column birthdate'
                        onClick={ () => changeOrderItem('nacimiento') }
                    >
                        <p>Nacimiento</p>
                        {
                            (orderBy === 'nacimiento') &&
                            <span
                                className={`${!ascNacimiento && 'desc'}`}
                            ><ArrowDownSmallIcon /></span>
                        }
                    </div>
                    <div 
                        className='column created'
                        onClick={ () => changeOrderItem('creacion') }
                    >
                        <p>Creado</p>
                        {
                            (orderBy === 'creacion') &&
                            <span
                                className={`${!ascCreacion && 'desc'}`}
                            ><ArrowDownSmallIcon /></span>
                        }
                    </div>
                    <div 
                        className='column last'
                        onClick={ () => changeOrderItem('ultimaReserva') }
                    >
                        <p>Última reserva</p>
                        {
                            (orderBy === 'ultimaReserva') &&
                            <span
                                className={`${!ascUltimaReserva && 'desc'}`}
                            ><ArrowDownSmallIcon /></span>
                        }
                    </div>
                </div>
                <div className='table-scrollable'>
                {
                    !isLoading &&
                    userList.map( usuario => (
                        <ClienteRowItem
                            key={ usuario._id }
                            usuario={ usuario }
                            editCliente={editCliente}
                            deleteCliente={deleteClienteModal}
                        />
                    ))
                }
                {
                    searchTxt.length > 0 && userList.length === 0 &&
                    <h3>No hay clientes que cumplan con el criterio de búsqueda</h3>
                }
                </div>
            </div>
            {
                ( totalUsuarios > LIMIT ) &&
                <div className='paginacion'>
                    <div 
                        className='pag-btn'
                        style={{ 
                            backgroundColor: colores.color6, 
                            color: colores.colorPrincipal,
                            opacity: from > 1 ? 1 : 0,
                            pointerEvents: from > 1 ? 'all' : 'none'
                        }}
                        onClick={ () => paginacion(-1000) }
                    >{ '<<' }</div>
                    <div 
                        className='pag-btn'
                        style={{ 
                            backgroundColor: colores.color6,
                            color: colores.colorPrincipal,
                            opacity: from > 1 ? 1 : 0,
                            pointerEvents: from > 1 ? 'all' : 'none'
                        }}
                        onClick={ () => paginacion(-1) }
                    >{ '<' }</div>
                    <div className='pag-number'>
                        Página: { Math.ceil(from / LIMIT) + 1 }
                    </div>
                    <div 
                        className='pag-btn'
                        onClick={ () => paginacion(1) }
                        style={{ 
                            backgroundColor: colores.color6,
                            color: colores.colorPrincipal,
                            opacity: from < (Math.ceil( totalUsuarios / LIMIT) * LIMIT) - LIMIT ? 1 : 0,
                            pointerEvents: from < (Math.ceil( totalUsuarios / LIMIT) * LIMIT) - LIMIT ? 'all' : 'none'
                        }}
                    >{ '>' }</div>
                    <div 
                        className='pag-btn'
                        onClick={ () => paginacion(1000000000) }
                        style={{ 
                            backgroundColor: colores.color6,
                            color: colores.colorPrincipal,
                            opacity: from < (Math.ceil( totalUsuarios / LIMIT) * LIMIT) - LIMIT ? 1 : 0,
                            pointerEvents: from < (Math.ceil( totalUsuarios / LIMIT) * LIMIT) - LIMIT ? 'all' : 'none'
                        }}
                    >{ '>>' }</div>
                </div>
            }
            {
                isLoading &&
                <div 
                    className='loading-clientes'
                >
                    <div className='bg-loading'></div>   
                    <p
                        style={{ color: colores.colorPrincipal }}
                    >Cargando...</p>
                </div>
            }
        </div>
        <ModalEliminarCliente
            data = { dataModalEliminar }
            setModalResponse = { deleteClienteResponse }
            modalOpened = { modalEliminarClienteOpened }
            setModalOpened = { setModalEliminarClienteOpened }
        />
        </>
    )
}
